<template>
  <div class="container">
    <div class="mt-7">
      <h1 class="text-center display-3">Terms And Conditions</h1>
      <div v-html="tac"></div>
    </div>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      tac: ""
    };
  },
  metaInfo: {
    title: "Terms And Conditions"
  },
  components: {},
  mounted() {
    this.$axios.get("tac/").then(res => {
      this.tac = res.data.content;
    });
  }
};
</script>
<style></style>
